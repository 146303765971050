const Icons = {
  dribbble: (
    <svg
      className="cursor-pointer hover:scale-150 ease-in-out duration-300 fill-midnight-black hover:fill-purple-link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47936 2 2 6.47936 2 12C2 17.5206 6.47936 22 12 22C17.5099 22 22 17.5208 22 12C22 6.47922 17.5099 2 12 2ZM13.2489 8.5232C12.0921 6.42196 10.8514 4.66061 10.3687 3.99325C10.8926 3.89007 11.4384 3.83829 12 3.83829C14.0117 3.83829 15.8577 4.56889 17.2836 5.77585C16.9212 6.23032 15.6989 7.56779 13.2489 8.5232ZM11.2461 9.14209C7.90603 9.99802 4.94487 10.083 4.04692 10.0912C4.61822 7.7174 6.23488 5.74138 8.37644 4.67675C8.76767 5.21642 10.0278 7.01343 11.2461 9.14209ZM14.0743 10.1131C16.6374 9.02737 18.0135 7.57672 18.4747 7.02872C19.4803 8.33061 20.0969 9.94409 20.1663 11.6876C19.3988 11.5471 17.0565 11.1728 14.6631 11.4476C14.6493 11.414 14.6359 11.3802 14.6221 11.3453C14.5979 11.2845 14.5724 11.2202 14.5411 11.1471C14.3962 10.803 14.2363 10.452 14.0743 10.1131ZM3.81702 12.0106V11.9548C4.74682 11.9609 8.3688 11.898 12.1236 10.7986C12.3027 11.149 12.4709 11.5054 12.6327 11.8637C12.5941 11.8748 12.5554 11.8859 12.5179 11.8972L12.514 11.8985C8.60725 13.159 6.33316 16.3573 5.74256 17.275C4.54612 15.8558 3.81702 14.0137 3.81702 12.0106ZM16.8074 18.6068C16.6495 17.7579 16.2069 15.6608 15.307 13.1376C17.5091 12.8423 19.437 13.3066 20.0299 13.4771C19.6584 15.5753 18.4706 17.3968 16.8074 18.6068ZM7.11387 18.5583C7.49182 17.8659 9.22299 15.1039 13.3748 13.6118C14.4282 16.3827 14.8979 18.7205 15.0547 19.5891C14.108 19.9759 13.078 20.183 12 20.183C10.1683 20.183 8.47363 19.5784 7.11387 18.5583ZM13.5201 13.5591L13.5231 13.5577L13.5213 13.5587L13.5201 13.5591Z"
        className=""
      />
    </svg>
  ),
  github: (
    <svg
      className="cursor-pointer hover:scale-150 ease-in-out duration-300 fill-midnight-black hover:fill-purple-link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0258 2C6.51676 2 2.05176 6.465 2.05176 11.974C2.05176 16.38 4.90876 20.119 8.87276 21.439C9.37176 21.529 9.55176 21.222 9.55176 20.958C9.55176 20.721 9.54376 20.093 9.54076 19.262C6.76576 19.864 6.17976 17.924 6.17976 17.924C5.72776 16.772 5.07276 16.465 5.07276 16.465C4.16776 15.846 5.14176 15.86 5.14176 15.86C6.14376 15.93 6.66876 16.888 6.66876 16.888C7.55876 18.412 9.00476 17.972 9.57076 17.717C9.66176 17.072 9.92176 16.632 10.2058 16.383C7.99176 16.132 5.66376 15.276 5.66376 11.453C5.66376 10.366 6.05276 9.474 6.68776 8.778C6.58676 8.525 6.24176 7.51 6.78676 6.138C6.78676 6.138 7.62376 5.869 9.52876 7.159C10.3268 6.938 11.1778 6.827 12.0248 6.823C12.8738 6.827 13.7258 6.938 14.5208 7.159C16.4268 5.868 17.2628 6.138 17.2628 6.138C17.8078 7.51 17.4658 8.525 17.3618 8.778C18.0018 9.474 18.3858 10.365 18.3858 11.453C18.3858 15.286 16.0558 16.128 13.8338 16.375C14.1888 16.683 14.5088 17.291 14.5088 18.221C14.5088 19.555 14.4968 20.631 14.4968 20.958C14.4968 21.225 14.6748 21.535 15.1838 21.437C19.1458 20.115 21.9998 16.379 21.9998 11.974C21.9998 6.465 17.5348 2 12.0258 2Z"
      />
    </svg>
  ),
  linkedin: (
    <svg
      className="cursor-pointer hover:scale-150 ease-in-out duration-300 fill-midnight-black hover:fill-purple-link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 21H17V14.25C17 13.1914 15.8121 12.3058 14.7535 12.3058C13.6949 12.3058 13 13.1914 13 14.25V21H9.00001V9H13V11C13.6623 9.92857 15.3564 9.23727 16.525 9.23727C18.9965 9.23727 21 11.2786 21 13.75V21ZM7 21H3V9H7V21ZM5.00001 3C6.10458 3 7.00001 3.89543 7.00001 5C7.00001 6.10457 6.10458 7 5.00001 7C3.89544 7 3.00001 6.10457 3.00001 5C3.00001 3.89543 3.89544 3 5.00001 3Z" />
    </svg>
  ),
  instagram: (
    <svg
      className="cursor-pointer hover:scale-150 ease-in-out duration-300 fill-midnight-black hover:fill-purple-link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.999 7.377C9.44495 7.377 7.37595 9.447 7.37595 12C7.37595 14.554 9.44495 16.624 11.999 16.624C14.551 16.624 16.622 14.554 16.622 12C16.622 9.447 14.551 7.377 11.999 7.377ZM11.999 15.004C10.34 15.004 8.99495 13.659 8.99495 12.001C8.99495 10.342 10.34 8.998 11.999 8.998C13.658 8.998 15.001 10.342 15.001 12.001C15.001 13.659 13.658 15.004 11.999 15.004Z" />
      <path d="M16.806 8.285C17.4013 8.285 17.884 7.80237 17.884 7.207C17.884 6.61164 17.4013 6.129 16.806 6.129C16.2106 6.129 15.728 6.61164 15.728 7.207C15.728 7.80237 16.2106 8.285 16.806 8.285Z" />
      <path d="M20.533 6.111C20.064 4.902 19.109 3.946 17.9 3.479C17.201 3.216 16.462 3.075 15.714 3.059C14.751 3.017 14.446 3.005 12.004 3.005C9.56195 3.005 9.24895 3.005 8.29395 3.059C7.54795 3.074 6.80895 3.215 6.10995 3.479C4.89995 3.946 3.94495 4.902 3.47695 6.111C3.21395 6.811 3.07295 7.549 3.05795 8.297C3.01495 9.259 3.00195 9.564 3.00195 12.007C3.00195 14.449 3.00195 14.76 3.05795 15.717C3.07295 16.465 3.21395 17.203 3.47695 17.904C3.94595 19.112 4.90095 20.068 6.11095 20.536C6.80695 20.808 7.54595 20.962 8.29595 20.986C9.25895 21.028 9.56395 21.041 12.006 21.041C14.448 21.041 14.761 21.041 15.716 20.986C16.463 20.971 17.202 20.829 17.902 20.567C19.111 20.098 20.066 19.143 20.535 17.934C20.798 17.234 20.939 16.496 20.954 15.748C20.997 14.786 21.01 14.481 21.01 12.038C21.01 9.59501 21.01 9.285 20.954 8.328C20.941 7.57 20.801 6.819 20.533 6.111ZM19.315 15.643C19.308 16.219 19.204 16.79 19.004 17.331C18.699 18.118 18.078 18.74 17.292 19.042C16.757 19.241 16.193 19.345 15.622 19.353C14.672 19.397 14.404 19.408 11.968 19.408C9.52995 19.408 9.28095 19.408 8.31295 19.353C7.74395 19.346 7.17795 19.241 6.64395 19.042C5.85495 18.741 5.22995 18.119 4.92495 17.331C4.72895 16.797 4.62295 16.232 4.61395 15.662C4.57095 14.712 4.56095 14.444 4.56095 12.008C4.56095 9.571 4.56095 9.322 4.61395 8.353C4.62095 7.777 4.72495 7.207 4.92495 6.666C5.22995 5.877 5.85495 5.256 6.64395 4.954C7.17795 4.756 7.74395 4.651 8.31295 4.643C9.26395 4.6 9.53095 4.588 11.968 4.588C14.405 4.588 14.655 4.588 15.622 4.643C16.193 4.65 16.757 4.755 17.292 4.954C18.078 5.257 18.699 5.879 19.004 6.666C19.2 7.2 19.306 7.765 19.315 8.335C19.358 9.28601 19.369 9.553 19.369 11.99C19.369 14.426 19.369 14.688 19.326 15.644H19.315V15.643Z" />
    </svg>
  ),
  spotify: (
    <svg
      className="cursor-pointer hover:scale-150 ease-in-out duration-300 fill-midnight-black hover:fill-purple-link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.01 2.019C6.51504 2.019 2.01904 6.515 2.01904 12.01C2.01904 17.504 6.51504 22 12.01 22C17.504 22 22 17.504 22 12.01C22 6.515 17.554 2.019 12.01 2.019ZM16.605 16.455C16.406 16.754 16.056 16.855 15.755 16.656C13.406 15.206 10.459 14.906 6.96204 15.705C6.61404 15.807 6.31404 15.557 6.21404 15.256C6.11304 14.906 6.36304 14.608 6.66404 14.507C10.459 13.657 13.757 14.008 16.354 15.607C16.704 15.756 16.754 16.155 16.605 16.455ZM17.805 13.708C17.554 14.057 17.105 14.207 16.754 13.957C14.057 12.311 9.96204 11.809 6.81504 12.809C6.41704 12.91 5.96504 12.709 5.86604 12.311C5.76504 11.909 5.96604 11.459 6.36504 11.359C10.011 10.261 14.508 10.811 17.604 12.71C17.904 12.859 18.054 13.358 17.805 13.708ZM17.904 10.909C14.707 9.012 9.36204 8.812 6.31404 9.763C5.81504 9.911 5.31404 9.612 5.16604 9.163C5.01604 8.661 5.31404 8.162 5.76504 8.012C9.31204 6.963 15.157 7.162 18.854 9.363C19.303 9.612 19.453 10.212 19.203 10.661C18.953 11.011 18.354 11.159 17.904 10.909Z" />
    </svg>
  ),
};
export default Icons;
